#navbar {
    top: 0;
    position: sticky;
    position: -webkit-sticky;
    z-index: 3;
}

#navbar .navbar-brand {
    font-family: "Sail", serif;
}

#navbar .searchbar {
    z-index: 2;
    padding: 1em;
    /* background: rgb(32,38,57); */
    /* background: linear-gradient(112deg, rgba(32,38,57,0.85) 0%, rgba(63,76,119,0.85) 100%, rgba(63,76,119,0.85) 100%); */
    /* transition: all 1s ease-in-out !important; */
}

#navbar .searchbar .input-group {
    /* width: 70vw; */
    margin: 0 auto;
}